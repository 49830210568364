<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">

            <!-- Restaurant Filter -->
            <div class="col-xl mb-2 mb-xl-0">
              <v-select
                class="v-select-filter"
                placeholder="Select restaurant.."
                v-model="selectedRestaurants"
                :options="restaurantNames"
                :reduce="c => c.value"
                :disabled="!!restaurantId"
                @input="getAllItems"
                multiple
              />
            </div>

            <!-- Search -->
            <div class="col-xl mb-2 mb-xl-0">
              <CInput
                class="mb-0"
                type="search"
                placeholder="Search.."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No reviews found!',
      }"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >
      <!-- User Orders -->
      <template #user_orders="{item}">
        <td class="text-center">
          <CLink :to="{ name: 'Order Details', params: { id: item.rrroid }}" target="_blank">
            <CIcon name="cil-basket" />
          </CLink>
        </td>
      </template>

      <!-- Reviewer -->
      <template #reviewer="{item}">
        <td>
          <template v-if="item.order">
            {{ item.user_name }}
            <div class="small">{{ item.order.user.email }}</div>
          </template>
          <span v-else>Deleted Order !</span>
        </td>
      </template>

      <!-- Anonymously -->
      <template #anonymous="{item}">
        <td>
          <CSwitch
            :checked.sync="item.anonymous"
            labelOn="YES"
            labelOff="NO"
            color="success"
            shape="pill"
            @update:checked="() => updateAnonymous(item)"
          />
        </td>
      </template>

      <!-- Restaurant Name -->
      <template #restaurant_name="{item}">
        <td>
          <CLink :to="{name: 'View Restaurant',params: { id: item.restaurant_id }}" target="_blank">
            {{ item.restaurant.restaurant_name }}
          </CLink>
        </td>
      </template>

      <!-- Review Text -->
      <template #review_text="{item}">
        <td style="overflow-wrap: anywhere;">
            {{ item.review_text }}
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="text-center">
          <CButton
            v-if="item.eol == 0"
            size="sm"
            color="danger"
            variant="ghost"
            shape="pill"
            @click="deleteItem(item, index)"
          >
            <CIcon name="cil-trash" />
          </CButton>
          <template v-else><CIcon name="cil-trash" class="text-danger" /></template>
        </td>
      </template>
    </CDataTable>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions",                   label: "",                  filter: false, sorter: false, _style: "min-width: 75px;" },
        { key: "id",                        label: "ID",                filter: false },
        { key: "created_at",                label: "Created",           filter: false, _style: "min-width: 150px;" },
        { key: "anonymous",                 label: "Anonymously",       filter: false, _style: "min-width: 120px;" },
        { key: "reviewer",                  label: "Reviewer",          filter: false, _style: "min-width: 200px;" },
        { key: "restaurant_name",           label: "Restaurant",        filter: false, _style: "min-width: 200px;" },
        { key: "food_quality",              label: "Rating Food",       filter: false, _style: "min-width: 75px;" },
        { key: "delivery",                  label: "Rating Delivery",   filter: false, _style: "min-width: 75px;" },
        { key: "review_text",               label: "Review",            filter: false },
        { key: "user_orders",               label: "Orders",            filter: false, _style: "min-width: 75px; text-align: center;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Restaurants Filter işlemleri için
      restaurantNames: [],
      selectedRestaurants: null,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage =  this.queryPage;

    if(this.userId) {
      this.search = 'c#' + this.userId;
    }

    await Promise.all([
      this.getRestaurantNames(),
    ]);

    await this.getAllItems();
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: this.currentRouteName, query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    currentRouteName () { return this.$route.name },
    restaurantId () { return this.$route.params.restaurant_id },
    userId () { return this.$route.params.user_id },
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.REVIEWS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          filter: this.selectedRestaurants
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = item.eol == 1 ? ' strikeout' : '';
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },

    updateAnonymous(item) {
      this.$axios.put(
          this.$backend.REVIEWS.UPDATE.replace("{id}", item.id),
          { anonymous: item.anonymous }
        )
        .catch(() => {
          item.anonymous = !item.anonymous;
        });
    },
    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.REVIEWS.DELETE.replace("{id}", item.id)
              )
              .then((response) => {
                // this.$delete(this.loadedItems, index);

                // Soft delete
                let item = this.loadedItems[index];
                item._classes += ' strikeout';
                item.eol = 1;

                this.showErrorAlert = false
                this.successAlertMessage = {itemName: 'Review', message: 'deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
                // this.total--
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data;
                this.showErrorAlert = true;
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    async getRestaurantNames() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.restaurantNames = response.data;
          if(this.restaurantId) {
            this.selectedRestaurants = this.restaurantNames.find(item => item.value == this.restaurantId).value;
          }
        })
        .catch(() => { this.restaurantNames = [] })
    },

    // Filters
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.search = null;
        this.filterBy = "unlinked";
        !this.restaurantId && (this.selectedRestaurants = []);
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table th, .table td {
    padding: 0.5rem 0.25rem;
    vertical-align: middle;
}
</style>
