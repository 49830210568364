<template>
  <CRow>

    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-star" />
          <h5 class="d-inline ml-2">Reviews</h5>
          <div class="card-header-actions">
            <CButton v-if="restaurantId" color="link" :to="{name: 'View Restaurant', params: {'id': restaurantId}}" class="ml-2">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back To Restaurant
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "Reviews",
  components: {
    BackendTable,
  },
  computed: {
    restaurantId() { return this.$route.params.restaurant_id; },
  },
};
</script>
